import dayjs from 'dayjs'
import saveAs from 'file-saver'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useState } from 'react'
import styled from '@emotion/styled'
import I18n, { Trans } from '../../i18n'
import useCsvChannel from '../../lib/use-csv-channel'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import useAuthorization from '../../lib/use-authorization'
import AlertBox from '../AlertBox'
import Button from '../Button'
import ErrorBox from '../forms/ErrorBox'
import MultiSelect from '../forms/MultiSelect'
import RadioButton from '../RadioButton'
import FullHeightCalendar from './FullHeightCalendar'
import Modal from './Modal'
import { pick } from 'lodash'

const initialDownloadState = {
  status: 'idle',
  header: '',
  chunks: [],
  meta: {}
}
function downloadReducer(state, action) {
  switch (action.type) {
    case 'reset':
      return { ...initialDownloadState }
    case 'start':
      return { ...state, status: 'loading' }
    case 'meta':
      return {
        ...state,
        meta: { filename: action.filename, count: action.count }
      }
    case 'header':
      return { ...state, header: action.data }
    case 'chunk': {
      // Each chunk has a number to indicate its order position, because they
      // can arrive in a different order depending on network conditions and
      // size of a chunk.
      const chunks = [...state.chunks]
      chunks[action.number] = action.data

      // The last chunk is `null` which indicates the end. When this last chunk
      // is present and all other chunks are not empty, the stream is complete.
      const hasNullSignal = chunks[chunks.length - 1] === null
      const arrivedChunks = chunks.filter(chunk => chunk !== undefined)
      const isFilled = chunks.length === arrivedChunks.length
      const status = hasNullSignal && isFilled ? 'completed' : state.status

      return { ...state, chunks, status }
    }
  }
}

const CALENDAR_PRESETS = {
  0: 'YESTERDAY',
  1: 'THIS_WEEK',
  2: 'THIS_MONTH',
  3: 'THIS_YEAR',
  4: 'TWO_DAYS_AGO',
  5: 'LAST_WEEK',
  6: 'LAST_MONTH',
  7: 'LAST_YEAR'
}

function getCalendarPresetTimeranges() {
  return {
    YESTERDAY: {
      start: dayjs()
        .add(-1, 'day')
        .startOf('day'),
      end: dayjs()
        .add(-1, 'day')
        .endOf('day')
    },

    THIS_WEEK: {
      start: dayjs().startOf('week'),
      end: dayjs().endOf('week')
    },

    THIS_MONTH: {
      start: dayjs().startOf('month'),
      end: dayjs().endOf('month')
    },

    THIS_YEAR: {
      start: dayjs().startOf('year'),
      end: dayjs().endOf('year')
    },

    TWO_DAYS_AGO: {
      start: dayjs()
        .add(-2, 'day')
        .startOf('day'),
      end: dayjs()
        .add(-2, 'day')
        .endOf('day')
    },

    LAST_WEEK: {
      start: dayjs()
        .add(-1, 'week')
        .startOf('week'),
      end: dayjs()
        .add(-1, 'week')
        .endOf('week')
    },

    LAST_MONTH: {
      start: dayjs()
        .add(-1, 'month')
        .startOf('month'),
      end: dayjs()
        .add(-1, 'month')
        .endOf('month')
    },

    LAST_YEAR: {
      start: dayjs()
        .add(-1, 'year')
        .startOf('year'),
      end: dayjs()
        .add(-1, 'year')
        .endOf('year')
    }
  }
}
function MultiLocationCsvModal({
  onRequestClose,
  isOpen,
  favLocationIds,
  userFullName
}) {
  const { location, locations } = useLocation()
  const [filter] = useFilter()
  const [connectToCsvChannel] = useCsvChannel()
  const [from, setFrom] = useState(filter.from)
  const [to, setTo] = useState(filter.to)
  const [resolution, setResolution] = useState(filter.resolution)
  const [download, setDownload] = useReducer(
    downloadReducer,
    initialDownloadState
  )
  const [selectableLocations, setSelectableLocations] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])
  const [activeCalendarPreset, setActiveCalendarPreset] = useState(null)
  const [formErrors, setFormErrors] = useState([])
  const { permissions } = useAuthorization()

  useEffect(() => void setFrom(filter.from), [filter.from])
  useEffect(() => void setTo(filter.to), [filter.to])
  useEffect(() => void setResolution(filter.resolution), [filter.resolution])

  useEffect(() => {
    if (download.status === 'completed') {
      const bom = '\ufeff' // The BOM is required so that Microsoft Excel can correctly handle the encoding. Without the BOM, Umlauts get scrambled and you have to manually import the CSV in a specific way. With the BOM, everything just works. Apple's Numbers doesn't care either way.
      const contents = [bom, download.header, ...download.chunks].join('')
      const blob = new Blob([contents], {
        type: 'application/csv;charset=utf-8'
      })
      saveAs(blob, download.meta.filename)
      setDownload({ type: 'reset' })
    }
  }, [download])
  useEffect(() => {
    if (!locations) {
      return
    }

    const locationsAsSelectorOptions = Object.values(
      pick(locations, permissions.locations.accessibleForCsvExport)
    ).map(location => ({
      value: location,
      label: `${location.city} - ${location.name}`
    }))

    setSelectableLocations(locationsAsSelectorOptions)

    const currentLocationAsSelectableOption = locationsAsSelectorOptions.filter(
      selectableLocation => {
        return selectableLocation.value.id === location.id
      }
    )

    setSelectedLocations(currentLocationAsSelectableOption)
  }, [location.id, location.name, locations])

  // calendar date range (months) to display
  const calendarMin = dayjs(location.metadata.earliestMeasurementAt)
    .startOf('year')
    .toDate()
  const calendarMax = dayjs()
    .endOf('year')
    .toDate()

  function getDownloadProgress() {
    if (!download.meta.count || !download.chunks) return 0

    const expectedLinesCount = download.meta.count
    const currentLinesCount = download.chunks.join('').match(/^/gm).length

    return currentLinesCount / expectedLinesCount
  }

  function handleDateChangeStart({ start }) {
    setFrom(
      dayjs(start)
        .startOf('day')
        .toDate()
    )
    setTo(null)
  }

  function handleDateChange({ start, end }) {
    let earlierDate = start
    let laterDate = end
    if (dayjs(laterDate).isBefore(earlierDate, 'day')) {
      earlierDate = end
      laterDate = start
    }

    setFrom(
      dayjs(earlierDate)
        .startOf('day')
        .toDate()
    )
    setTo(
      dayjs(laterDate)
        .endOf('day')
        .toDate()
    )
  }

  function handleCsvReceive(payload) {
    setDownload(payload)
  }

  function validateForm() {
    const newFormErrors = []

    if (!selectedLocations || selectableLocations.length === 0) {
      newFormErrors.push(
        I18n.t('location.download.plase_select_at_least_one_location_warning')
      )
      return false
    }

    setFormErrors(newFormErrors)
    return true
  }

  function handleCsvDownload() {
    if (!validateForm()) {
      return
    }

    if (download.status === 'loading') {
      alert(I18n.t('location.download.please_wait'))
      return
    }

    setDownload({ type: 'start' })

    connectToCsvChannel({ onReceive: handleCsvReceive })
      .then(subscription => {
        const locationIds = selectedLocations.map(selectionItem => {
          return selectionItem.value.id
        })

        subscription.requestMultiLocationCsv({
          locationIds: locationIds,
          resolution,
          from: from.toISOString(),
          to: to.toISOString()
        })
      })
      .catch(error => {
        console.error(error) // eslint-disable-line no-console
        alert(error.message)
      })
  }

  function handleRequestClose() {
    if (download.status === 'loading') {
      alert(I18n.t('location.download.please_wait'))
      return
    }

    onRequestClose()
  }

  function handleTimeRangePresetButtonClick(selectedPreset) {
    if (selectedPreset === activeCalendarPreset) {
      handleDateChange({ from: filter.from, to: filter.to })
      setActiveCalendarPreset(null)
      return
    }

    const timeRanges = getCalendarPresetTimeranges()
    handleDateChange(timeRanges[CALENDAR_PRESETS[selectedPreset]])
    setActiveCalendarPreset(selectedPreset)
  }

  function handleSetMyFavouritesButtonClick() {
    const favLocations = favLocationIds.map(id => {
      const indexOfFavouriteLocation = selectableLocations.findIndex(
        selectableLocation => {
          return selectableLocation.value.id === id
        }
      )

      return selectableLocations[indexOfFavouriteLocation]
    })
    setSelectedLocations(favLocations)
  }

  function handleLocationSelect(newSelectedLocations) {
    setSelectedLocations(newSelectedLocations)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      showCloseButton
      title={'location.download.headline'}
      fullHeight
      fullWidth
    >
      <div css={{ flex: '1 0 50%', padding: '1.25rem', paddingTop: 0 }}>
        {selectedLocations.length >= 10 && (
          <AlertBox error css={{ marginBottom: '1rem' }}>
            <Trans
              id="location.download.cannot_select_more_than_10_locations_alert"
              values={{ name: userFullName }}
              copy
            />
          </AlertBox>
        )}

        {formErrors.length > 0 && <ErrorBox errors={formErrors}></ErrorBox>}

        <Group
          css={{
            marginTop: '1.5rem'
          }}
        >
          <GroupHeaderRow>
            <GroupTitle
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Trans id="location.download.with_locations" />
            </GroupTitle>
            <TextButton onClick={handleSetMyFavouritesButtonClick}>
              {I18n.t('location.download.select_my_favourite_locations')}
            </TextButton>
          </GroupHeaderRow>

          <MultiSelect
            css={{ zIndex: 5 }}
            options={selectableLocations}
            placeholder={I18n.t('location.download.city_country_district')}
            onChange={handleLocationSelect}
            value={selectedLocations}
            limit={10}
            closeMenuOnSelect={false}
          />
        </Group>

        <Group>
          <GroupTitle>
            <Trans id="location.download.select_timerange" />
          </GroupTitle>

          <ButtonGroup>
            <Row>
              <StyledButton
                active={activeCalendarPreset === 0}
                onClick={() => {
                  handleTimeRangePresetButtonClick(0)
                }}
              >
                {I18n.t('location.download.timerange_presets.yesterday')}
              </StyledButton>
              <StyledButton
                active={activeCalendarPreset === 1}
                onClick={() => {
                  handleTimeRangePresetButtonClick(1)
                }}
              >
                {I18n.t('location.download.timerange_presets.this_week')}
              </StyledButton>
              <StyledButton
                active={activeCalendarPreset === 2}
                onClick={() => {
                  handleTimeRangePresetButtonClick(2)
                }}
              >
                {I18n.t('location.download.timerange_presets.this_month')}
              </StyledButton>
              <StyledButton
                active={activeCalendarPreset === 3}
                onClick={() => {
                  handleTimeRangePresetButtonClick(3)
                }}
              >
                {I18n.t('location.download.timerange_presets.this_year')}
              </StyledButton>
            </Row>
            <Row>
              <StyledButton
                active={activeCalendarPreset === 4}
                onClick={() => {
                  handleTimeRangePresetButtonClick(4)
                }}
              >
                {I18n.t('location.download.timerange_presets.two_days_ago')}
              </StyledButton>
              <StyledButton
                active={activeCalendarPreset === 5}
                onClick={() => {
                  handleTimeRangePresetButtonClick(5)
                }}
              >
                {I18n.t('location.download.timerange_presets.last_week')}
              </StyledButton>
              <StyledButton
                active={activeCalendarPreset === 6}
                onClick={() => {
                  handleTimeRangePresetButtonClick(6)
                }}
              >
                {I18n.t('location.download.timerange_presets.last_month')}
              </StyledButton>
              <StyledButton
                active={activeCalendarPreset === 7}
                onClick={() => {
                  handleTimeRangePresetButtonClick(7)
                }}
              >
                {I18n.t('location.download.timerange_presets.last_year')}
              </StyledButton>
            </Row>
          </ButtonGroup>

          <FullHeightCalendar
            css={{ marginTop: '1rem', minHeight: 300 }}
            startDate={from}
            endDate={to}
            min={calendarMin}
            max={calendarMax}
            minDate={dayjs(location.metadata.earliestMeasurementAt).toDate()}
            maxDate={new Date()}
            onDateChangeStart={handleDateChangeStart}
            onDateChange={handleDateChange}
            canSafelySetHeight={isOpen}
          />
        </Group>

        <Group>
          <GroupTitle>
            <Trans id="location.download.resolution_headline" />
          </GroupTitle>
          <Row justify="flex-start">
            <RadioButton
              key={'hour'}
              id={'hour'}
              name="'hour'"
              value={'hour'}
              label={I18n.t('location.download.resolution.hour')}
              checked={resolution === 'hour'}
              onChange={() => setResolution('hour')}
            />

            <RadioButton
              css={{ marginLeft: '1rem' }}
              key={'day'}
              id={'day'}
              name={'day'}
              value={'day'}
              label={I18n.t('location.download.resolution.day')}
              checked={resolution === 'day'}
              onChange={() => setResolution('day')}
            />

            <RadioButton
              css={{ marginLeft: '1rem' }}
              key={'month'}
              id={'month'}
              name={'month'}
              value={'month'}
              label={I18n.t('location.download.resolution.month')}
              checked={resolution === 'month'}
              onChange={() => setResolution('month')}
            />
          </Row>
        </Group>

        <Group css={{ marginBottom: '0.5rem' }}>
          <Button
            baseColor
            onClick={handleCsvDownload}
            disabled={
              download.status === 'loading' ||
              !to ||
              selectedLocations.length === 0
            }
            css={{ width: '100%' }}
          >
            {download.status === 'loading' ? (
              <>
                <Trans id="location.download.in_progress" /> (
                {Math.floor(getDownloadProgress() * 100)}%)
              </>
            ) : (
              <Trans id="location.download.download_button" />
            )}
          </Button>
          <Group>
            <Button.Link
              href="https://hystreet.com/api-docs"
              target="_blank"
              css={{ padding: '0.875rem' }}
            >
              {I18n.t('location.download.apihint')}
            </Button.Link>
          </Group>
        </Group>
      </div>
    </Modal>
  )
}

MultiLocationCsvModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  favLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  userFullName: PropTypes.string.isRequired
}

const Group = styled.div`
  padding-top: 0.5rem;
  margin-bottom: 1.5rem;
`

const GroupHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const GroupTitle = styled.h3`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 0 100%;
`

const TextButton = styled.button`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 1rem;

  color: ${p => p.theme.chroma.base.css()};
  &:hover {
    color: ${p => p.theme.chroma.base.alpha(0.5).css()};
  }
`

const StyledButton = styled.button`
  flex: 1 0 24%;
  width: 24%;
  max-width: 24%;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid;
  padding: 0.5rem 0.25rem;
  text-align: left;
  color: ${p => (p.active ? p.theme.chroma.base.css() : '#979797')};

  ${p =>
    p.active
      ? {
          backgroundColor: p.theme.chroma.lighterGrey.css(),
          borderColor: p.theme.chroma.lighterGrey.css(),
          color: p.theme.chroma.base.css()
        }
      : {
          backgroundColor: p.theme.chroma.white.css(),
          borderColor: p.theme.chroma.grey.css(),
          color: p.theme.chroma.grey.css()
        }}

  &:hover {
    background-color: ${p =>
      p.active ? p.theme.chroma.lighterGrey.css() : p.theme.chroma.grey.css()};
    color: ${p =>
      p.active ? p.theme.chroma.base.css() : p.theme.chroma.white.css()};
    border-color: ${p =>
      p.active ? p.theme.chroma.lighterGrey.css() : p.theme.chroma.grey.css()};
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => p.justify || 'space-between'};
  align-items: center;
  flex: 1 0 100%;
  margin-top: 0.5rem;
  vertical-align: bottom;
`

export default MultiLocationCsvModal
