import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled'
import I18n, { Trans } from '../i18n'
import Checkbox from '../components/forms/Checkbox';
import Typography from '../components/Typography';

const OptOut = () => {
  const getConsentStatusText = (optedOut) => {
    return optedOut ? I18n.t('pages.opt_out.allowed') : I18n.t('pages.opt_out.forbidden')
  }
  
  const initializeOptoutStatusFromMatomo = () => {
    window._paq.push([function() {
      const matomoOptedOut = this.isUserOptedOut()
      const consentStatusForCheckboxLabel = getConsentStatusText(matomoOptedOut);

      setOptedOut(matomoOptedOut);
      setOptoutText(consentStatusForCheckboxLabel);
    }]);
  }

  const handleOptOutChange = (element) => {
    const optOutChecked = element.target.checked
    const consentStatusForCheckboxLabel = getConsentStatusText(optOutChecked);

    optOutChecked ? window._paq.push(['forgetUserOptOut']) :  window._paq.push(['optUserOut'])
    setOptedOut(optOutChecked);
    setOptoutText(consentStatusForCheckboxLabel);
  }

  const [optedOut, setOptedOut] = useState(false);
  const [optoutText, setOptoutText] = useState("");

  useEffect(() => {
    initializeOptoutStatusFromMatomo();
  }
  , []);

  return (
    <OptoutPageContainer>
        <Typography tag="h3">
          {I18n.t('pages.opt_out.headline')}
        </Typography>

        <Typography tag="p">
          {I18n.t('pages.opt_out.description')}
        </Typography>

        <Typography tag="p">
          {I18n.t('pages.opt_out.input_description')}
        </Typography>

        <InputContainer>
          <Checkbox name="opt_out" label={optoutText} checked={optedOut} onChange={handleOptOutChange} />
        </InputContainer>
        
        <FindOutMoreText>
          <Trans id="pages.opt_out.find_out_more" />
        </FindOutMoreText>
    </OptoutPageContainer>
  );
};

const InputContainer = styled.div`
  margin-top: 1rem;
`

const OptoutPageContainer = styled.div`
  padding: 2.4375rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: .5rem;
`

const FindOutMoreText = styled.div`
  margin-top: .5rem;
  font-size: 0.875rem;
  line-height: 1.5625rem;
`

export default OptOut;