import React, { useState } from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { navigationStructure } from '../lib/types'
import I18n from '../i18n'

import Logo from './assets/logo.svg'
import MenuButton from './MenuButton'
import Button from './Button'
import * as Icon from './Icon'
import Dropdown from './Dropdown'

function Navigation({ pages }) {
  const [shouldDisplayMenu, setShouldDisplayMenu] = useState(false)

  function handleMenuButtonClick() {
    setShouldDisplayMenu(currentShouldDisplay => !currentShouldDisplay)
  }

  return (
    <Bar initial="closed" animate={shouldDisplayMenu ? 'open' : 'closed'}>
      <ContentWrapper>
        <a href="/">
          <Logo height={36} />
        </a>
        <MenuButton onClick={handleMenuButtonClick} />
        <DesktopNavigation>
          <NavigationItems pages={pages} />
        </DesktopNavigation>
      </ContentWrapper>
      <MobileNavigation variants={variants.base} open={shouldDisplayMenu}>
        <motion.div
          variants={variants.links}
          transition={{ duration: 0.3 }}
          css={{ overflowY: 'auto' }}
        >
          <NavigationItems pages={pages} />
        </motion.div>
        <MobileBackground
          variants={variants.background}
          transition={{ duration: 0.3 }}
        />
      </MobileNavigation>
    </Bar>
  )
}

Navigation.propTypes = {
  pages: navigationStructure
}

const variants = {
  base: {
    open: { pointerEvents: 'initial' },
    closed: { pointerEvents: 'none' }
  },
  background: {
    open: { scaleY: 1 },
    closed: {
      scaleY: 0,
      transition: {
        delay: 0.3
      }
    }
  },
  links: {
    closed: { y: -10, opacity: 0 },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3
      }
    }
  }
}

const Bar = styled(motion.header)`
  position: relative;
  z-index: 5;
  background-color: ${p => p.theme.chroma.white.hex()};
  box-shadow: 0 0.125rem 0.4375rem 0 rgba(0, 0, 0, 0.17);

  @media print {
    display: none;
  }
`

const ContentWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0 auto;
  max-width: 1440px;

  ${p => p.theme.breakpoints.desktopUp} {
    padding: 0 1rem;
  }
`

const MobileNavigation = styled(motion.nav)`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  max-height: 100vh;
  padding: 1rem;
  padding-top: 5rem;
  text-align: center;

  display: ${p => (p.open ? 'block' : 'none')};

  ${p => p.theme.breakpoints.desktopUp} {
    display: none;
  }
`

const MobileBackground = styled(motion.div)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${p => p.theme.chroma.white.hex()};
  box-shadow: 0 0.125rem 0.3125rem 0 rgba(0, 0, 0, 0.17);
  transform-origin: top center;
`

const DesktopNavigation = styled.nav`
  display: none;
  ${p => p.theme.breakpoints.desktopUp} {
    display: block;
  }
`

function NavigationItems({ pages }) {
  return (
    <NavigationItemList>
      {pages.map(page => (
        <NavigationItem key={page.name}>
          {page.isButton ? (
            <>
              <HideAboveAndBelowBreakpoint>
                <IconButtonLink href={page.link} {...page.attrs}>
                  {page.name === I18n.t('navigation.logout') && <Icon.Logout />}
                  {page.name === I18n.t('navigation.login') && <Icon.Login />}
                </IconButtonLink>
              </HideAboveAndBelowBreakpoint>
              <HideInBetweenBreakpoint>
                <Button.Link href={page.link} {...page.attrs}>
                  {page.name}
                </Button.Link>
              </HideInBetweenBreakpoint>
            </>
          ) : page.isDropdown ? (
            <Dropdown
              dropdownLinks={page.dropdownLinks}
              isActive={page.isActive}
              link={page.link}
              name={page.name}
            ></Dropdown>
          ) : (
            <NavigationLink
              isActive={page.isActive}
              href={page.link}
              {...page.attrs}
            >
              {page.name}
            </NavigationLink>
          )}
        </NavigationItem>
      ))}
    </NavigationItemList>
  )
}

NavigationItems.propTypes = {
  pages: navigationStructure
}

const NavigationItemList = styled.ul`
  display: flex;
  align-items: center;

  ${p => p.theme.breakpoints.desktop} {
    flex-direction: column;
  }
`

const NavigationItem = styled.li`
  ${p => p.theme.breakpoints.desktopUp} {
    flex: 0 1 auto;
  }

  ${p => p.theme.breakpoints.desktop} {
    width: 100%;
  }
`

const NavigationLink = styled.a`
  display: block;
  padding: 1rem;
  font-weight: bold;
  color: ${p =>
    p.isActive ? p.theme.chroma.accent.hex() : p.theme.chroma.base.hex()};

  ${p => p.theme.breakpoints.desktop} {
    font-size: 1rem;
  }

  ${p => p.theme.breakpoints.desktopUp} {
    font-size: 0.8rem;
    padding: 1.75rem 0.75rem;
    cursor: pointer;
    &:hover {
      background-color: ${p => p.theme.chroma.nearlyWhite.css()};
      color: ${p => p.theme.chroma.accent.hex()};
      & ul {
        display: block;
      }
    }
  }
`

const IconButtonLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  color: ${props => props.theme.chroma.accent.hex()};
`

const HideAboveAndBelowBreakpoint = styled.div`
  width: 40px;
  height: 40px;
  display: block;
  @media (min-width: 1381px) {
    display: none;
  }
  @media (max-width: 991px) {
    display: none;
  }
`

const HideInBetweenBreakpoint = styled.div`
  display: block;
  @media (min-width: 992px) and (max-width: 1380px) {
    display: none;
  }
`

export default Navigation
