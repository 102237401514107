import React from 'react'
import styled from '@emotion/styled'

import I18n, { Trans } from '../i18n'

import Button from '../components/Button'
import Headline from '../components/Headline'
import Section from '../components/Section'

function ApiDocs() {
  return (
    <Section>
      <Section.Wrapper css={{ paddingBottom: '6rem' }}>
        <Content>
          <Headline breakout css={{ marginBottom: '1.25rem' }}>
            {I18n.t('pages.developer.api.documentation.headline')}
          </Headline>
          <Text>
            <Trans id="pages.developer.api.documentation.text" />
          </Text>
          <Button.Link
            outline
            target="_blank"
            href="https://hystreet.com/api-docs"
          >
            {I18n.t('pages.developer.api.documentation.button_text')}
          </Button.Link>
        </Content>
      </Section.Wrapper>
    </Section>
  )
}

const Content = styled.div`
  padding: 5rem 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${props => props.theme.breakpoints.tabletUp} {
    width: 60%;
  }
`

const Text = styled.div`
  line-height: 1.5;
  margin-bottom: 1.5rem;
`

export default ApiDocs
